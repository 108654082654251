import '../../index.css';
import '../../App.css';
import './Login.css';

import {
  getAuth,
  GoogleAuthProvider,
  //   isSignInWithEmailLink,
  //   signInWithEmailLink,
  sendSignInLinkToEmail,
} from 'firebase/auth';
import StyledFirebaseAuth from './StyledFirbaseAuth';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const actionCodeSettings = {
  url: 'https://app.transparentpricerx.com/', // Change this to your actual login page URL
  handleCodeInApp: true, // Must be true for email link sign-in
};

const sendLoginLink = async (email: string) => {
  const auth = getAuth();

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    localStorage.setItem('emailForSignIn', email); // Store email to verify later
    alert('Login link sent! Check your email.');
  } catch (error) {
    console.error('Error sending login link:', error);
  }
};

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    // {
    //   provider: EmailAuthProvider.PROVIDER_ID,
    //   signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    // },
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false, // Prevent default redirect
  },
};

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const auth = getAuth();

  return (
    <>
      <Stack className="login-s">
        <Stack className="login-c">
          <Typography variant="h2" align="center">
            Sign in with Email Link
          </Typography>
          <TextField
            label="Email"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) &&
              email !== ''
            }
            helperText={
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) &&
              email !== ''
                ? 'Enter a valid email'
                : ''
            }
          />
          <Button
            variant="contained"
            size="large"
            onClick={() => sendLoginLink(email)}>
            Send Login Link
          </Button>
          <Divider />
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </Stack>
      </Stack>
    </>
  );
};

export default LoginPage;
