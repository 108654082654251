/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useState } from 'react';
import { formValues } from '../../../utils/types';
import { TextField } from '@mui/material';

interface DateFieldProps {
  isMedicareOnly: boolean;
  setOpenAgeDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (value: string) => void;
  setIsMedicare: (value: boolean) => void;
  calculateAgeEligibility: (
    dob: string,
    minMax: string,
    thresholdAge: number
  ) => boolean;
  disability: string;
  errors: { [key: string]: string };
  setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  values: formValues;
}
dayjs.extend(isBetween);

function DateField({ onChange, errors, values }: DateFieldProps) {
  const [value, setValue] = useState<string>(values.patient.dob || '');
  const formatDateInput = (input: string) => {
    // Remove non-numeric characters
    const numbersOnly = input.replace(/\D/g, '');

    let formatted = '';
    let mm = '';
    let dd = '';
    let yyyy = '';

    if (numbersOnly.length >= 1) {
      mm = numbersOnly.slice(0, 2);
      if (parseInt(mm, 10) > 12) mm = '12'; // Ensure month max is 12
      formatted = mm;
    }
    if (numbersOnly.length >= 3) {
      dd = numbersOnly.slice(2, 4);
      if (parseInt(dd, 10) > 31) dd = '31'; // Ensure day max is 31
      formatted = `${mm}/${dd}`;
    }
    if (numbersOnly.length >= 5) {
      yyyy = numbersOnly.slice(4, 8);
      formatted = `${mm}/${dd}/${yyyy}`;
    }

    return formatted;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatDateInput(e.target.value);
    setValue(formattedValue);
    onChange(formattedValue);
  };

  return (
    <>
      <TextField
        required
        label="Birthday"
        name="dob"
        value={value}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errors.dob}
        helperText={errors.dob}
        placeholder="MM/DD/YYYY"
        inputProps={{ maxLength: 10 }}
      />
    </>
  );
}
export default DateField;
