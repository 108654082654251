/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, Stack, Typography } from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { eligibilityValues } from './eligibilityCalculator';

const jjGroup = [
  'Symtuza',
  'Tremfya',
  'Xarelto',
  'Simponi Aria',
  'Simponi',
  'Remicade',
  'InFLIXimab',
  'Stelara',
  'Invokana',
];

function EligibilityCalculatorResults({
  values,
  scenario,
  selectedMed,
  source,
}: {
  values: eligibilityValues;
  scenario: string;
  insuranceDataList: DocumentData[] | null;
  selectedMed: any;
  source: string;
}) {
  const [buttonUrl, setButtonUrl] = useState<string>(
    `https://app.transparentpricerx.com/enroll?utm_source=brokerportal&utm_id=TPRX&utm_campaign=${source}`
  );
  const appendUrlParametersToLinks = useCallback(() => {
    // Create a URLSearchParams object based on the current URL's search part
    const searchParams = new URLSearchParams(window.location.search);
    // Check if there are any search parameters
    if (Array.from(searchParams.keys()).length > 0) {
      // Create a URL object based on the link's href attribute
      const linkUrl = new URL(buttonUrl);

      // Append each search parameter to the link's URL
      searchParams.forEach((value, key) => {
        linkUrl.searchParams.set(key, value);
      });
      setButtonUrl(linkUrl.toString());
    }
  }, [buttonUrl]);

  useEffect(() => {
    appendUrlParametersToLinks();
  }, [appendUrlParametersToLinks, values]);

  return (
    selectedMed && (
      <>
        <Stack
          key={scenario}
          spacing={2}
          width={{
            xs: '100%',
            md: '70rem',
          }}
          textAlign={'center'}
          margin={'auto'}
          p={{
            xs: 2,
            md: 4,
          }}
          sx={{
            backgroundColor: '#f4f9fe',
            borderRadius: 4,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
          }}>
          {scenario === 'generic' && (
            <>
              <Typography variant="h3">
                The generic medication cost by strength is as follows:
              </Typography>
              <Grid container justifyContent="center" spacing={2}>
                {selectedMed.options.map((option: any) => (
                  <Grid item key={option.strength}>
                    <Stack direction={'row'} spacing={1}>
                      <Typography variant="body1" fontWeight={600}>
                        {option.strength}:
                      </Typography>
                      <Typography variant="body1">
                        {option.price} for a 90 days supply
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              <Button
                variant="contained"
                color="primary"
                target="_blank"
                href={buttonUrl}>
                Enroll Now
              </Button>
            </>
          )}

          {scenario === 'papOnlyMedNotEligible' && (
            <>
              <Typography variant="h3">
                Your client does not qualify for the Patient Assistance Program
                (PAP) because their income exceeds the eligibility threshold
              </Typography>
              {selectedMed.name === 'Ozempic' && (
                <Typography variant="h4" fontWeight={500}>
                  We also offer a generic version of this medication under the
                  name Wegovy which does not require any income nor insurance
                  eligibility
                </Typography>
              )}
            </>
          )}
          {scenario === 'papOnlyMedEligible' && (
            <>
              <Typography variant="h3">
                This medication is not covered by the current insurance product.
              </Typography>
              {selectedMed.name === 'Ozempic' && (
                <Typography variant="h4" fontWeight={500}>
                  We also offer a generic version of this medication under the
                  name Wegovy which does not require any income nor insurance
                  eligibility
                </Typography>
              )}
            </>
          )}
          {scenario === 'papEligible' && (
            <>
              <Typography variant="h2"> Qualify for PAP!</Typography>
              <Typography variant="h3">
                The cost for {selectedMed.name} is as follows:
              </Typography>
              <Grid container justifyContent="center" spacing={1}>
                {selectedMed.options.map((option: any) => (
                  <Grid item key={option.strength}>
                    <Stack direction={'row'}>
                      <Typography variant="body1" fontWeight={600}>
                        {option.strength}:
                      </Typography>
                      <Typography variant="body1">
                        {option.price} for a 90 days supply
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              <Stack textAlign={'left'} spacing={1} pb={2}>
                <Typography variant="body1" fontWeight={600}>
                  Strict Compliance Required:
                </Typography>
                <Typography variant="body1">
                  The client must adhere precisely to the provided steps, as
                  there are no alternative methods. This adherence is essential
                  to meet manufacturer regulations. Print out and wet ink sign
                  the application.
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  Income verification:
                </Typography>
                <Typography variant="body1">
                  Provide income verification documents to their healthcare
                  provider, attached to the application for it to be faxed
                  directly to the manufacturer.
                </Typography>
                {selectedMed.name.includes('Eliquis') && (
                  <Typography variant="body1">
                    The client must include Pharmacy Printout for the year.
                    showing expenses exceeding 3% of your yearly household
                    income.
                  </Typography>
                )}
                {selectedMed.name.includes('Breo Ellipta') ||
                  selectedMed.name.includes('Anoro Ellipta') ||
                  (selectedMed.name.includes('Trelegy Ellipta') && (
                    <Typography variant="body1">
                      The client must attach documentation that include all
                      pages of the most recent Medicare Part D / Part C
                      prescription drug plan statement (Explanation of Benefits
                      - EOB), showing a total of $600 paid for prescriptions in
                      the current calendar year.
                    </Typography>
                  ))}
                {jjGroup.includes(selectedMed.name) && (
                  <Typography variant="body1">
                    The client must include Pharmacy Printout Requirement for
                    Household Medication Expenses: Attach a Pharmacy Printout
                    for the year, showing expenses exceeding 4% of the client
                    yearly household income. This printout can include all
                    medication expenses for the client household.
                  </Typography>
                )}
                <Typography variant="body1" fontWeight={600}>
                  Delivery Time: 2 to 3 weeks:
                </Typography>
                <Typography variant="body1">
                  Once the healthcare provider eFaxed the paperwork Directly to
                  the Manufacturer.
                </Typography>
              </Stack>

              <Button
                variant="contained"
                color="primary"
                target="_blank"
                href={buttonUrl}>
                Enroll Now
              </Button>
            </>
          )}

          {scenario === 'papIncomeHigh' && (
            <>
              <Typography variant="h3">
                Your client does not qualify for the Patient Assistance Program
                (PAP) because their income exceeds the eligibility threshold
              </Typography>
              {selectedMed.name === 'Ozempic' && (
                <Typography variant="h4" fontWeight={500}>
                  We also offer a generic version of this medication under the
                  name Wegovy which does not require any income nor insurance
                  eligibility
                </Typography>
              )}
            </>
          )}
        </Stack>
      </>
    )
  );
}

export default EligibilityCalculatorResults;
