/* eslint-disable @typescript-eslint/no-explicit-any */

import log from 'loglevel';
import { sendErrorToSlack } from './notificationFunctions';

log.setLevel(process.env.NODE_ENV === 'production' ? 'error' : 'debug');
const ERROR_EXPIRATION_TIME = 3600000;
// Set to store unique error identifiers
const sentErrors = new Set();

const logger = {
  log: log.info.bind(log),
  info: log.info.bind(log),
  warn: log.warn.bind(log),
  error: log.error.bind(log),
  debug: log.debug.bind(log),
  // Function to log and send errors to Slack
  logError: (error: any, additionalData = {}) => {
    const errorIdentifier = `${error.message}-${error.stack}`;

    if (sentErrors.has(errorIdentifier)) {
      log.info('Duplicate error detected. Skipping Slack notification.');
      return;
    }

    // Add the error to the set and set a timeout to remove it
    sentErrors.add(errorIdentifier);
    setTimeout(() => {
      sentErrors.delete(errorIdentifier);
    }, ERROR_EXPIRATION_TIME);

    const errorDetails = {
      message: error.message,
      stack: error.stack,
      ...additionalData,
      timestamp: new Date().toISOString(),
      environment: process.env.NODE_ENV,
      userAgent: navigator.userAgent,
      url: window.location.href,
    };

    log.error('Captured Error:', errorDetails);
    sendErrorToSlack(errorDetails);
  },
};
export default logger;
