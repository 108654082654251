/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { MedicareEligibilityValues } from './MedicareCalculator';

const jjGroup = [
  'Symtuza',
  'Tremfya',
  'Xarelto',
  'Simponi Aria',
  'Simponi',
  'Remicade',
  'InFLIXimab',
  'Stelara',
  'Invokana',
];
function MedicareCalculatorResults({
  values,
  // scenario,
  selectedMed,
  source,
}: {
  values: MedicareEligibilityValues;
  // scenario: string;
  selectedMed: any;
  source: string;
}) {
  const [buttonUrl, setButtonUrl] = useState<string>(
    `https://app.transparentpricerx.com/enroll?utm_source=brokerportal&utm_id=medicare&utm_campaign=${source}&company=Medicare`
  );
  const [resultHeigth, setResultHeight] = useState(0);
  const getDeviceType = useCallback(() => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }, []);
  const deviceType = getDeviceType();
  const appendUrlParametersToLinks = useCallback(() => {
    // Create a URLSearchParams object based on the current URL's search part
    const searchParams = new URLSearchParams(window.location.search);
    // Check if there are any search parameters
    if (Array.from(searchParams.keys()).length > 0) {
      // Create a URL object based on the link's href attribute
      const linkUrl = new URL(buttonUrl);
      // Append each search parameter to the link's URL
      searchParams.forEach((value, key) => {
        linkUrl.searchParams.set(key, value);
      });
      setButtonUrl(linkUrl.toString());
    }
  }, [buttonUrl]);

  useEffect(() => {
    appendUrlParametersToLinks();
  }, [appendUrlParametersToLinks, values]);

  useEffect(() => {
    const stackElement = document.getElementById(
      `medicare-eligibility-results`
    );
    if (stackElement) {
      setResultHeight(
        deviceType === 'desktop'
          ? 350 + Number(stackElement.clientHeight)
          : 550 + Number(stackElement.clientHeight)
      );
    }
  }, [selectedMed, deviceType]);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'resize',
        value: resultHeigth,
      },
      '*'
    );
  }, [resultHeigth]);

  return (
    <>
      <Stack
        id={'medicare-eligibility-results'}
        spacing={2}
        width={{
          xs: '100%',
          md: '70rem',
        }}
        textAlign={'center'}
        margin={'auto'}
        p={{
          xs: 2,
          md: 4,
        }}
        sx={{
          backgroundColor: '#f4f9fe',
          borderRadius: 4,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        }}>
        <>
          <Typography variant="h2"> Qualify for PAP!</Typography>
          <Stack textAlign={'left'} spacing={1}>
            <Typography variant="body1" fontWeight={600}>
              Strict Compliance Required:
            </Typography>

            <Typography variant="body1">
              The client must adhere precisely to the provided steps, as there
              are no alternative methods. This adherence is essential to meet
              manufacturer regulations. Print out and wet ink sign the
              application.
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              Income verification:
            </Typography>
            <Typography variant="body1">
              Only your current copy of your Social Security Award Letter and
              your spouse’s Social Security Award Letter, if applicable are
              required as proof of income. There is no need to include
              additional documents, such as investment statements, stock
              ownership, or other financial details.
            </Typography>
            {selectedMed.name.includes('Eliquis') && (
              <Typography variant="body1">
                The client must include Pharmacy Printout for the year. showing
                expenses exceeding 3% of your yearly household income.
              </Typography>
            )}
            {selectedMed.name.includes('Breo Ellipta') ||
              selectedMed.name.includes('Anoro Ellipta') ||
              (selectedMed.name.includes('Trelegy Ellipta') && (
                <Typography variant="body1">
                  The client must attach documentation that include all pages of
                  the most recent Medicare Part D / Part C prescription drug
                  plan statement (Explanation of Benefits - EOB), showing a
                  total of $600 paid for prescriptions in the current calendar
                  year.
                </Typography>
              ))}
            {jjGroup.includes(selectedMed.name) && (
              <Typography variant="body1">
                The client must include Pharmacy Printout Requirement for
                Household Medication Expenses: Attach a Pharmacy Printout for
                the year, showing expenses exceeding 4% of the client yearly
                household income. This printout can include all medication
                expenses for the client household.
              </Typography>
            )}
            <Typography variant="body1" fontWeight={600}>
              Delivery Time: 2 to 3 weeks:
            </Typography>
            <Typography variant="body1">
              Once the healthcare provider eFaxed the paperwork Directly to the
              Manufacturer.
            </Typography>
          </Stack>
          <Button
            variant="contained"
            color="primary"
            target="_blank"
            href={buttonUrl}>
            Enroll Now
          </Button>
        </>
      </Stack>
    </>
  );
}

export default MedicareCalculatorResults;
