/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  createFilterOptions,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { collection, DocumentData } from 'firebase/firestore';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';
import { fplCalculator } from '../../utils/utilFuntions';
import ClientEligibilityResults from './ClientEligibilityResults';
import '../../App.css';
import theme from '../../theme';

export interface eligibilityValues {
  income: string;
  householdSize: number;
  medicationName: string;
  company: string;
  product: string;
  plan: string;
}

// Utility function for debouncing
const useDebounce = (func: Function, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunc = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => func(...args), delay);
    },
    [func, delay]
  );
  return debouncedFunc;
};

function ClientEligibility() {
  const [values, setValues] = useState<eligibilityValues>({
    income: '',
    householdSize: 1,
    medicationName: '',
    company: '',
    product: '',
    plan: '',
  });
  const [incomeValue, setIncomeValue] = useState<string | null>(
    values.income.toString() || ''
  );

  const handleChange = (e: { target: { name: string; value: unknown } }) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    if (value === '' || value === undefined) return;
  };
  function handleIncomeChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = e.target.value.replace(/\D/g, '');
    handleChange(e);
    if (value === '') {
      setValues({ ...values, income: '' });
      return;
    }
    value !== ''
      ? (value = '$' + parseInt(value).toLocaleString().replace(/\./g, ','))
      : (value = '');
    setIncomeValue(value);
  }

  const [medications, loadingMedications] = useCollection(
    collection(db, 'medicationsBackup'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );
  const [medicationsList, setMedicationsList] = useState<DocumentData[] | null>(
    null
  );
  const [insuranceData, loadingInsuranceData] = useCollection(
    collection(db, 'insuranceCompanies'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [insuranceDataList, setInsuranceDataList] = useState<
    DocumentData[] | null
  >(null);
  const [insuranceProductList, setInsuranceProductList] = useState<
    DocumentData[] | null
  >(null);
  const [insurancePlanList, setInsurancePlanList] = useState<
    DocumentData[] | null
  >(null);

  const [clientFPL, setClientFPL] = useState<number>(0);
  const [scenario, setScenario] = useState('none');
  const [selectedMed, setSelectedMed] = useState<DocumentData | null>(null);
  const [isGeneric, setIsGeneric] = useState<boolean>(false);

  const checkProductsEligibilty = useCallback(() => {
    if (!selectedMed) return;
    if (selectedMed.brand) {
      if (values.company === 'medicare') {
        setScenario('medicareBrand');
        return;
      }

      if (selectedMed.name === 'Xolair') {
        Number(values.income.replace(/\D/g, '')) < 150000
          ? setScenario('papEligible')
          : setScenario('papIncomeHigh');
      }
      const isntEligible = clientFPL > selectedMed.fpl;
      if (isntEligible) {
        setScenario('papIncomeHigh');
        return;
      } else {
        setScenario('papEligible');
      }
    } else {
      if (values.company === 'medicare') {
        setScenario('medicareGeneric');
        return;
      } else {
        setScenario('generic');
        return;
      }
    }
  }, [clientFPL, selectedMed, values.company, values.income]);

  useEffect(() => {
    if (loadingMedications) return;
    let orderdList = medications?.docs.map((doc) => doc.data());
    orderdList = orderdList?.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    setMedicationsList(orderdList!);
  }, [loadingMedications, medications]);

  useEffect(() => {
    if (loadingInsuranceData) return;
    let orderdList = insuranceData?.docs.map((doc) => doc.data());
    orderdList?.push({
      name: `I don't have insurance coverage at this time`,
      products: [],
    });
    orderdList = orderdList?.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    orderdList?.unshift({ name: `Medicare`, products: [] });
    setInsuranceDataList(orderdList!);
  }, [loadingInsuranceData, insuranceData]);

  useEffect(() => {
    const insuranceProducts = insuranceDataList?.find(
      (item) => item.name === values.company
    );
    if (
      !insuranceProducts ||
      values.company === "I don't have insurance coverage at this time" ||
      values.company === "I can't find my insurance company"
    )
      return;
    insuranceProducts.products = insuranceProducts.products.sort(
      (a: { name: string }, b: { name: string }) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    );
    setInsuranceProductList(insuranceProducts?.products);
  }, [insuranceDataList, values]);

  useEffect(() => {
    const insurancePlans = insuranceProductList?.find(
      (item) => item.name === values.product
    );
    if (!insurancePlans) {
      setInsurancePlanList(null);
      return;
    }

    insurancePlans.plans = insurancePlans.plans.sort(
      (a: { name: string }, b: { name: string }) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    );
    setInsurancePlanList(insurancePlans?.plans);
  }, [insuranceProductList, values]);

  useEffect(() => {
    if (!medicationsList) return;
    if (values.company === "I can't find my insurance company") {
      setIsGeneric(true);
    } else {
      setIsGeneric(false);
    }
    setMedicationsList(medicationsList);
  }, [values.company, medicationsList]);

  const selectMedicationList = useMemo(() => {
    if (!medicationsList) return [];
    return medicationsList.filter((med) => {
      if (isGeneric) {
        return !med.brand;
      } else return true;
    });
  }, [medicationsList, isGeneric]);

  useEffect(() => {
    if (values.income === '' && values.company !== 'medicare') return;
    setClientFPL(
      fplCalculator(
        values.company !== 'medicare' ? values.householdSize : 1,
        values.company !== 'medicare'
          ? parseInt(values.income.slice(1).replace(/,/g, ''))
          : 0
      )
    );
    if (values.medicationName) {
      setSelectedMed(
        medicationsList!.find(
          (item) =>
            item.name ===
            values.medicationName.slice(0, values.medicationName.indexOf(' ('))
        ) || null
      );
    }
  }, [medicationsList, values]);

  const addValuestoParams = useCallback((values: eligibilityValues) => {
    const params = new URLSearchParams(window.location.search);
    Object.entries(values).forEach(([key, value]) => {
      if (value === '' || value === null) {
        params.delete(key);
      } else {
        if (value === 'medicare') {
          params.delete('income');
          params.delete('householdSize');
          params.delete('product');
          params.delete('plan');
        }
        params.set(key, value.toString());
      }
    });
    const newUrl = `${params.toString()}`;
    const urlForHistory = `${window.location.pathname}?${newUrl}`;
    window.history.pushState({ path: newUrl }, '', urlForHistory);
    window.parent.postMessage({ path: newUrl }, '*');
    return params;
  }, []);

  const debouncedAddValuestoParams = useDebounce(addValuestoParams, 300);

  useEffect(() => {
    debouncedAddValuestoParams(values);
  }, [debouncedAddValuestoParams, values]);
  useEffect(() => {
    values.medicationName && checkProductsEligibilty();
  }, [checkProductsEligibilty, values.medicationName]);

  const filterMedicationsOptions = (options: any[], state: any) => {
    const inputValue = state.inputValue;
    return options.filter((option) => {
      if (
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.genericName.toLowerCase().includes(inputValue.toLowerCase())
      ) {
        return option;
      }
      return false;
    });
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
  });
  return (
    <>
      {medicationsList &&
      medicationsList.length > 0 &&
      insuranceDataList &&
      insuranceDataList.length > 0 ? (
        <Stack
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          sx={{
            paddingTop: {
              sm: 2,
              md: 8,
            },
          }}>
          <Stack spacing={8} className="container">
            <Stack spacing={4} alignItems={'center'}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={values.company !== `Medicare` ? 4 : 6}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    disablePortal
                    value={values.company || null}
                    options={insuranceDataList.map((option) => option.name)}
                    onChange={(_e, value) => {
                      values.product = '';
                      values.plan = '';
                      if (
                        value === `I don't have insurance coverage at this time`
                      ) {
                        setInsuranceProductList(null);
                        setInsurancePlanList(null);
                      }
                      const event = {
                        target: { name: 'company', value: value },
                      };
                      handleChange(event);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        name={`company`}
                        value={values.company || ''}
                        label={'Insurance Company'}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {values.company !== `Medicare` && (
                  <>
                    <Grid item xs={12} sm={4} key={'insurance product grid'}>
                      <TextField
                        key={'insurance product'}
                        fullWidth
                        select
                        required={
                          values.company ===
                            `I don't have insurance coverage at this time` ||
                          values.company === "I can't find my insurance company"
                            ? false
                            : true
                        }
                        name={'product'}
                        value={values.product || ''}
                        label={'Insurance Product'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          values.plan = '';
                          setInsurancePlanList(null);
                        }}
                        variant="outlined">
                        {insuranceProductList ? (
                          insuranceProductList?.map((item) => (
                            <MenuItem key={item.name} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value={''}>No Products Available</MenuItem>
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4} key={'insurance plan grid'}>
                      <TextField
                        key={'insurance plan'}
                        fullWidth
                        select
                        required={
                          (insurancePlanList &&
                            insurancePlanList.length === 1) ||
                          values.company ===
                            `I don't have insurance coverage at this time` ||
                          values.company === "I can't find my insurance company"
                            ? false
                            : true
                        }
                        name={'plan'}
                        value={values['plan'] || ''}
                        label={'Insurance Plan'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                        }}
                        variant="outlined">
                        {insurancePlanList ? (
                          insurancePlanList?.map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name ? item.name : ''}>
                              {item.name ? item.name : 'All'}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value={''}>No Plans Available</MenuItem>
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        id="income"
                        name="income"
                        label="Yearly Income"
                        onChange={handleIncomeChange}
                        value={
                          values.income.toString() !== '' ? incomeValue : ''
                        }
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        id="householdSize"
                        name="householdSize"
                        label="Household Number"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={values.householdSize || 1}
                        margin="normal"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={values.company !== `Medicare` ? 4 : 6}>
                  <Stack
                    sx={{ marginTop: values.company === 'medicare' ? 0 : 2 }}>
                    <Autocomplete
                      filterOptions={filterMedicationsOptions}
                      disablePortal
                      options={selectMedicationList}
                      value={
                        selectMedicationList.find(
                          (option) =>
                            option.name === values.medicationName.split(' (')[0]
                        ) || null
                      }
                      getOptionLabel={(option: any) =>
                        `${option.name} (${option.genericName})`
                      }
                      onChange={(_e, value: any) => {
                        handleChange({
                          target: {
                            name: 'medicationName',
                            value: !value
                              ? ''
                              : `${value.name} (${value.genericName})`,
                          },
                        });
                      }}
                      renderOption={(props, option: any) => {
                        const { key, ...optionProps } = props;
                        return (
                          <Stack
                            key={key}
                            component="li"
                            {...optionProps}
                            direction={'row'}>
                            <Stack direction={'column'} mr={'auto'}>
                              <Typography variant="body1">
                                {option.name}
                              </Typography>
                              <Typography variant="body2">
                                ({option.genericName})
                              </Typography>
                            </Stack>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: '1.1rem',
                                fontWeight: 600,
                                backgroundColor: option.brand
                                  ? theme.palette.primary.dark
                                  : theme.palette.primary.main,
                                color: theme.palette.common.white,
                                padding: '0.2rem 0.5rem',
                                borderRadius: '0.5rem',
                                marginLeft: '2rem',
                              }}>
                              {option.brand ? 'Brand' : 'Generic'}
                            </Typography>
                          </Stack>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          value={values.medicationName || null}
                          name={'medicationName'}
                          label={'Select Medication'}
                          variant="outlined"
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
              {scenario !== 'none' && (
                <ClientEligibilityResults
                  values={values}
                  scenario={scenario}
                  selectedMed={selectedMed}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <div className="loader-s">
          <div className="loader" />
        </div>
      )}
    </>
  );
}
export default ClientEligibility;
