import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { db } from '../../firebase-setup/firebase';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { formatPhoneNumber } from '../../utils/utilFuntions';

async function xorDecode(
  encodedStr: string,
  secret = '7?5O?S93}S~PYNBlR1Ss+o_LVy2U'
) {
  const decoded = atob(encodedStr); // Decode base64 without Buffer
  return decoded
    .split('')
    .map((char, i) =>
      String.fromCharCode(
        char.charCodeAt(0) ^ secret.charCodeAt(i % secret.length)
      )
    )
    .join('');
}
async function getDecodedParams() {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  const clientIdEncrypted = searchParams.get('clientId');
  const hpDocIdEncypted = searchParams.get('hpId');
  const hpFaxNumberEncrypted = searchParams.get('hpFax');
  const clientId = await xorDecode(clientIdEncrypted || '');
  const hpDocId = await xorDecode(hpDocIdEncypted || '');
  const hpFaxNumber = await xorDecode(hpFaxNumberEncrypted || '');

  return { clientId, hpDocId, hpFaxNumber };
}
function HpFaxUpdating() {
  const [params, setParams] = useState<{
    clientId: string;
    hpDocId: string;
    hpFaxNumber: string;
  } | null>(null);

  useEffect(() => {
    async function fetchParams() {
      const decodedParams = await getDecodedParams();
      setParams(decodedParams);
    }
    fetchParams();
  }, []);

  const { clientId, hpDocId, hpFaxNumber } = params || {};
  const [fieldError, setFieldError] = useState<string | null>(null);
  const [hpFax, setHpFax] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const updateHpFax = useCallback(
    async (clientId: string, hpDocId: string) => {
      try {
        const clientDoc = doc(collection(db, 'clients'), clientId);
        const hpDoc = doc(
          collection(clientDoc, 'healthcare-providers'),
          hpDocId
        );
        await updateDoc(hpDoc, {
          fax: hpFax,
        });
        setUpdated(true);
      } catch (error) {
        console.error('Error updating HP Fax:', error);
      }
    },
    [hpFax]
  );

  const validateFields = useCallback(
    (value: string) => {
      let error = '';

      // Ensure value is properly formatted before checking
      const formattedValue = value.trim();
      const formattedHpFaxNumber = hpFaxNumber!.trim();

      // Validate correct format
      if (!/^\(\d{3}\) \d{3} - \d{4}$/.test(formattedValue)) {
        error = 'Invalid fax number';
      }

      // Ensure the new fax number is different
      if (formattedValue === formattedHpFaxNumber) {
        error = 'Please enter a new fax number';
      }

      console.log('error', error);
      return error;
    },
    [hpFaxNumber]
  );

  const handleChangeWithValidation = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let fieldValue = event.target.value;

      fieldValue = formatPhoneNumber(event);
      // Perform validation
      const error = validateFields(fieldValue);
      setFieldError(error);
      setHpFax(fieldValue);
    },
    [validateFields]
  );

  useEffect(() => {
    if (!clientId || !hpDocId) {
      setError('Missing required parameters: clientId or hpDocId.');
    } else {
      setError(null);
    }
  }, [clientId, hpDocId]);

  return (
    <Stack
      sx={{
        height: {
          sm: 'calc(100vh - 96px)',
          md: 'calc(100vh - 64px)',
        },
      }}>
      {error ? (
        <Stack
          className="tprx-card"
          style={{
            margin: 'auto',
            width: '50rem',
            maxWidth: '90%',
          }}>
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            Error
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            An error occurred: please contact support.
          </Typography>
        </Stack>
      ) : updated ? (
        <Stack
          className="tprx-card"
          style={{
            margin: 'auto',
            width: '50rem',
            maxWidth: '90%',
          }}>
          <Stack spacing={1}>
            <Typography variant="h2" sx={{ textAlign: 'center' }}>
              Thank you!
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              We have successfully updated your healthcare provider's fax
              number. We will contact you incase of any issues, otherwise you
              can expect to recieve updates regarding your order processing via
              email and SMS
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Stack
          className="tprx-card"
          style={{
            margin: 'auto',
            width: '50rem',
            maxWidth: '90%',
          }}>
          <Stack spacing={1}>
            <Typography variant="h2" sx={{ textAlign: 'center' }}>
              Update Healthcare Provider Fax Number
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Enter the new fax number for your healthcare provider below.
              Please ensure the number is correct, as an incorrect fax number
              will cause delays in receiving your medication.
            </Typography>
          </Stack>
          <FormControl
            sx={{
              width: '100%',
            }}>
            <Stack spacing={2}>
              <TextField
                label="Enter Healthcare Provider Fax Number"
                variant="outlined"
                value={hpFax || ''}
                onChange={handleChangeWithValidation}
                error={Boolean(fieldError)}
                helperText={fieldError}
              />
              <Button
                disabled={Boolean(fieldError) || !hpFax || loading}
                variant="contained"
                color="primary"
                onClick={async () => {
                  setLoading(true);
                  await updateHpFax(clientId!, hpDocId!);
                  setLoading(false);
                }}>
                {loading ? 'Updating...' : 'Update HP Fax'}
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      )}
    </Stack>
  );
}
export default HpFaxUpdating;
