// import { useCollection } from 'react-firebase-hooks/firestore';
// import { db } from '../../../firebase-setup/firebase';
// import {
//   collection,
//   DocumentData,
//   getDocs,
//   updateDoc,
// } from 'firebase/firestore';
// import {
//   Alert,
//   createFilterOptions,
//   Snackbar,
//   Stack,
//   Typography,
// } from '@mui/material';
// import { memo, useCallback, useEffect, useMemo, useState } from 'react';
// import { storage } from '../../../firebase-setup/firebase';
// import {
//   ref,
//   uploadBytes,
//   listAll,
//   getMetadata,
//   StorageReference,
// } from 'firebase/storage';
// import { LoopsClient } from 'loops';
// import ClientSelector from './ClientSelector';
// import FaxUploaderTable from './FaxUploaderTable';
// const FaxUploader = memo(function FaxUploader() {
//   const [clientsData, loadingClientsData] = useCollection(
//     collection(db, 'clients'),
//     {
//       snapshotListenOptions: { includeMetadataChanges: true },
//     }
//   );
//   const [loadingFiles, setLoadingFiles] = useState(false);

import {
  // Alert,
  Button,
  CircularProgress,
  Grid,
  // Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import ClientSelector from './ClientSelector';
import { collection, DocumentData } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../../firebase-setup/firebase';
import AutomationTable from './AutomationTable';
import { StorageReference } from 'firebase/storage';

//   const [meds, loadingMeds] = useCollection(collection(db, 'medications'), {
//     snapshotListenOptions: { includeMetadataChanges: true },
//   });

//   const [tasks, loadingTasks] = useCollection(collection(db, 'tasks'), {
//     snapshotListenOptions: { includeMetadataChanges: true },
//   });
//   const [brandMeds, setBrandMeds] = useState<string[]>([]);

//   const [clientList, setClientList] = useState<string[]>([]);
//   const [clientRelatedTasks, setClientRelatedTasks] = useState<
//     DocumentData[] | null
//   >(null);
//   const [selectedClient, setSelectedClient] = useState<string>('');
//   const [selectClientName, setSelectClientName] = useState<string>('');
//   const [selectClientId, setSelectedClientId] = useState<string>('');
//   const [selectedClientJoinDate, setSelectedClientJoinDate] =
//     useState<string>('');
//   const [hpList, setHpList] = useState<
//     { docName: string; docFax: number; isAutomationActive: string }[]
//   >([]);
//   const [ordersList, setOrdersList] = useState<
//     { docName: string; medication: string }[]
//   >([]);

//   const [allFiles, setAllFiles] = useState<{
//     [key: string]: { files: File[]; id: string; hpFax: number }[];
//   }>({});
//   // const [isValid, setIsValid] = useState<boolean>(false);
//   const [uploadStatus, setUploadStatus] = useState<
//     'success' | 'error' | 'loading' | null
//   >(null);
//   const [resetFlag, setResetFlag] = useState(false);
//   const [clientFiles, setClientFiles] = useState<
//     {
//       file: StorageReference;
//       emailName: string;
//       docName: string;
//     }[]
//   >([]);

//   // const filterOptions = createFilterOptions({
//   //   matchFrom: 'any',
//   // });
//   const getClientHpList = useCallback(
//     async (clientDoc: DocumentData) => {
//       const hpCollectionRef = collection(
//         db,
//         `clients/${clientDoc.id}/healthcare-providers`
//       );
//       const hpSnapshot = await getDocs(hpCollectionRef);
//       const hpList = hpSnapshot.docs
//         .map((doc) => ({
//           docName:
//             doc.data().mname === '' || doc.data().mname === ' '
//               ? doc.data().fname + ' ' + doc.data().lname
//               : doc.data().fname +
//                 ' ' +
//                 doc.data().mname +
//                 ' ' +
//                 doc.data().lname,
//           docFax: Number(doc.data().fax.replace(/\D/g, '')),
//           isAutomationActive: doc.data().automation,
//         }))
//         .filter((hp) => hp.docName !== '' && hp.docName !== ' ');
//       const initFilesData = hpList.reduce<
//         Record<
//           string,
//           {
//             welcomeEmail: boolean;
//             firstFax: boolean;
//             faxReminder: boolean;
//             manufacturerFax: boolean;
//           }
//         >
//       >((acc, hp) => {
//         if (!hp.docName) {
//           console.error('Invalid hp.docName:', hp);
//           return acc; // Skip this iteration if docName is missing or invalid
//         }
//         acc[hp.docName] = {
//           welcomeEmail:
//             clientFiles?.some(
//               (file) =>
//                 file.docName === hp.docName && file.emailName === 'welcomeEmail'
//             ) || false,
//           firstFax:
//             clientFiles?.some(
//               (file) =>
//                 file.docName === hp.docName && file.emailName === 'firstFax'
//             ) || false,
//           faxReminder:
//             clientFiles?.some(
//               (file) =>
//                 file.docName === hp.docName && file.emailName === 'faxReminder'
//             ) || false,
//           manufacturerFax:
//             clientFiles?.some(
//               (file) =>
//                 file.docName === hp.docName &&
//                 file.emailName === 'callManufactureFax'
//             ) || false,
//         };
//         return acc;
//       }, {});
//       console.log('initFilesData', initFilesData);
//       setFileUploadedState(initFilesData);
//       setHpList(hpList);
//     },
//     [clientFiles]
//   );

//   const getClientOrderList = useCallback(async (clientDoc: DocumentData) => {
//     const orderCollectionRef = collection(db, `clients/${clientDoc.id}/orders`);
//     const orderSnapshot = await getDocs(orderCollectionRef);
//     const orderList = orderSnapshot.docs.map((doc) => {
//       return {
//         docName: doc.data().doctorName,
//         medication: doc.data().medicationName,
//       };
//     });
//     setOrdersList(orderList);
//   }, []);

//   const initAutomation = useCallback(
//     async (faxes: { files: File[]; id: string; hpFax: number }[]) => {
//       const firstFax = faxes.find((fax) => fax.id === 'firstFax')!;
//       const joinDate = new Date(selectedClientJoinDate);
//       const tasks = [
//         {
//           filesPath: firstFax.files.map(
//             (file) => `${selectClientName}/${file.name}`
//           ),
//           id: firstFax.id,
//           faxNumber: firstFax.hpFax,
//           clientJoinDate: selectedClientJoinDate,
//           clientId: selectClientId,
//           sendDate: joinDate.getTime() + 2 * 24 * 60 * 60 * 1000,
//           automationOrder: 0,
//         },
//       ];

//       try {
//         const response = await fetch(
//           // 'https://faxsenderscheduler-qqjaqi7y5q-uc.a.run.app',
//           'http://127.0.0.1:5001/transparent-rx/us-central1/faxSenderScheduler',
//           {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ tasks }),
//           }
//         );

//         if (!response.ok) {
//           throw new Error('Failed to schedule batch tasks');
//         }

//         const data = await response.json();
//         console.log('Batch tasks scheduled:', data);
//       } catch (error) {
//         console.error('Error scheduling batch tasks:', error);
//       }
//     },
//     [selectClientId, selectClientName, selectedClientJoinDate]
//   );

//   const handleClientChange = useCallback(
//     async (value: string) => {
//       if (!clientsData) return;
//       setSelectedClient(value);
//       setSelectClientName(value.replace(/\s+/g, '_').toLowerCase());
//       const clientDoc = clientsData!.docs.find((doc) => {
//         const data = doc.data();
//         return `${data.fname} ${data.lname} (${data.email})` === value;
//       });
//       if (clientDoc) {
//         const clientTasks = tasks!.docs
//           .filter((doc) => {
//             if (!doc.data().taskFiles) return false;
//             return (
//               doc.data().taskFiles[0].split('/')[0] ===
//               value.replace(/\s+/g, '_').toLowerCase()
//             );
//           })
//           .map((doc) => doc.data());
//         setSelectedClientId(clientDoc.data().ssn);
//         setClientRelatedTasks(clientTasks || null);
//         const joinDate = new Date(clientDoc.data().timestamp.seconds * 1000); // Convert to milliseconds
//         setSelectedClientJoinDate(joinDate.toISOString().split('T')[0]);
//         getClientHpList(clientDoc);
//         getClientOrderList(clientDoc);
//         // getFilesFromStorage(value.replace(/\s+/g, '_').toLowerCase());
//       } else {
//         setHpList([]);
//         setOrdersList([]);
//         setClientFiles([]);
//         setClientRelatedTasks(null);
//       }
//     },
//     [
//       clientsData,
//       getClientHpList,
//       getClientOrderList,
//       getFilesFromStorage,
//       tasks,
//     ]
//   );

//   const medicationType = useCallback(
//     (orders: string[], brandMeds: string[]) => {
//       let segment = '';
//       orders.forEach((order) => {
//         if (!order || order === '') return;
//         const fixedMedName = order.slice(0, order.indexOf(' ('));
//         if (
//           brandMeds.find((med) => med === fixedMedName) ||
//           brandMeds.find((med) => med === fixedMedName.split(' ')[0])
//         ) {
//           if (segment === 'Generic') {
//             segment = 'Both';
//             return;
//           }
//           segment = 'Brand';
//           return;
//         } else {
//           if (segment === 'Brand' || segment === 'Both') {
//             segment = 'Both';
//             return;
//           }
//           segment = 'Generic';
//         }
//       });
//       return segment;
//     },
//     []
//   );
//   // const isAutomationReady = useCallback(
//   //   (hp: { docName: string | number }) => {
//   //     return (
//   //       fileUploadedState[hp.docName]?.welcomeEmail &&
//   //       fileUploadedState[hp.docName]?.firstFax &&
//   //       fileUploadedState[hp.docName]?.faxReminder &&
//   //       fileUploadedState[hp.docName]?.manufacturerFax
//   //     );
//   //   },
//   //   [fileUploadedState]
//   // );
//   const createTableData = useCallback(
//     (
//       hpList: {
//         docName: string;
//         docFax: number;
//         isAutomationActive: string;
//       }[],
//       ordersList: { docName: string; medication: string }[]
//     ) => {
//       const rows: {
//         hp: {
//           docName: string;
//           docFax: number;
//           isAutomationActive: string;
//           isAutomationReady: boolean;
//         };
//         type: string;
//         faxes: {
//           required: boolean;
//           name: string;
//         }[];
//       }[] = [];

//       hpList.forEach((hp) => {
//         const hpMeds = ordersList
//           .map((order) => {
//             if (order.docName === hp.docName) return order.medication;
//           })
//           .filter((med): med is string => med !== undefined);
//         const type = medicationType(hpMeds, brandMeds);
//         const row = {
//           hp: {
//             docName: hp.docName,
//             docFax: hp.docFax,
//             isAutomationActive: hp.isAutomationActive || 'stopped',
//             isAutomationReady: isAutomationReady(hp),
//           },
//           type: type,
//           faxes: [
//             {
//               required: !clientFiles.some(
//                 (file) =>
//                   file.docName === hp.docName &&
//                   file.emailName === 'welcomeEmail'
//               ),
//               name: 'welcomeEmail',
//             },
//             {
//               required: !clientFiles.some(
//                 (file) =>
//                   file.docName === hp.docName && file.emailName === 'firstFax'
//               ),
//               name: 'firstFax',
//             },
//             {
//               required: !clientFiles.some(
//                 (file) =>
//                   file.docName === hp.docName &&
//                   file.emailName === 'faxReminder'
//               ),
//               name: 'faxReminder',
//             },
//             {
//               required: !clientFiles.some(
//                 (file) =>
//                   file.docName === hp.docName &&
//                   file.emailName === 'manufacturerFax'
//               ),
//               name: 'manufacturerFax',
//             },
//           ],
//         };
//         rows.push(row);
//       });
//       return rows;
//     },
//     [brandMeds, clientFiles, isAutomationReady, medicationType]
//   );

//   const uploadFile = useCallback(
//     async (file: File, emailName: string, clientName: string) => {
//       const storageRef = ref(
//         storage,
//         `/clientFiles/${clientName}/` + file.name
//       );

//       const docName = Object.keys(allFiles).find((key) =>
//         allFiles[key].some((fileObj) => fileObj.files.includes(file))
//       );

//       if (!docName) {
//         throw new Error(`Document name not found for file: ${file.name}`);
//       }

//       const metaData = {
//         customMetadata: {
//           emailName: emailName || '-',
//           docName: docName || '-',
//         },
//       };
//       try {
//         await uploadBytes(storageRef, file, metaData);
//         console.log(`File uploaded: ${file.name}`);
//       } catch (error) {
//         console.error(`Failed to upload file: ${file.name}`, error);
//         throw error; // Propagate the error
//       }
//     },
//     [allFiles]
//   );

//   const uploadFileGroup = useCallback(
//     async (fileObject: { files: File[]; id: string }, clientName: string) => {
//       try {
//         const uploadPromises = fileObject.files.map((file) =>
//           uploadFile(file, fileObject.id, clientName)
//         );

//         await Promise.all(uploadPromises); // Upload all files in parallel
//       } catch (error) {
//         console.error(`Error uploading file group ${fileObject.id}:`, error);
//         throw error; // Rethrow to propagate error to the main handler
//       }
//     },
//     [uploadFile]
//   );

//   // const handleValidationStateChange = useCallback(
//   //   (uploaderId: string, isValid: boolean, docName: string) => {
//   //     // Check if the docName exists and the current state matches the intended state
//   //     if (
//   //       fileUploadedState[docName] &&
//   //       fileUploadedState[docName][uploaderId] === isValid
//   //     )
//   //       return;

//   //     setFileUploadedState((prev) => {
//   //       // Ensure docName exists in the state
//   //       const updatedDocState = prev[docName] || {}; // Initialize if undefined

//   //       // Update only if there's an actual change
//   //       if (updatedDocState[uploaderId] === isValid) return prev;

//   //       return {
//   //         ...prev,
//   //         [docName]: {
//   //           ...updatedDocState,
//   //           [uploaderId]: isValid,
//   //         },
//   //       };
//   //     });
//   //   },
//   //   [fileUploadedState]
//   // );
//   const resetUploader = useCallback(() => {
//     setAllFiles((prev) => {
//       const updatedFiles = { ...prev };
//       Object.keys(updatedFiles).forEach((key) => {
//         updatedFiles[key] = [];
//       });
//       return updatedFiles;
//     });
//     // setIsValid(false);

//     setResetFlag(true); // Trigger reset in child components
//   }, []);
//   const updateDocStatus = useCallback(
//     async (docFax: string, status: string) => {
//       const hpCollectionRef = collection(
//         db,
//         `clients/${selectClientId}/healthcare-providers`
//       );
//       const hpSnapshot = await getDocs(hpCollectionRef);
//       const currentHp = hpSnapshot.docs.find((doc) => {
//         return doc.data().fax.replace(/\D/g, '') === docFax.replace(/\D/g, '');
//       });
//       if (currentHp) {
//         await updateDoc(currentHp.ref, { automation: status });
//       } else {
//         console.error('No healthcare provider found:', docFax);
//       }
//     },
//     [selectClientId]
//   );

//   const tableData = useMemo(() => {
//     if (!hpList.length || !ordersList.length) return [];
//     return createTableData(hpList, ordersList);
//   }, [hpList, ordersList, createTableData]);

//   const handleUploadAllFiles = useCallback(
//     async (faxNumber: number) => {
//       const allFileLists = Object.values(allFiles).flat();
//       const scheduledFaxes = allFileLists.filter(
//         (file) => file.id !== 'welcomeEmail' && file.hpFax === faxNumber
//       );
//       if (allFileLists.length === 0) {
//         console.error('No files to upload.');
//         return;
//       }

//       try {
//         setUploadStatus('loading'); // Set loading status
//         const uploadTasks = allFileLists.map((fileObject) =>
//           uploadFileGroup(fileObject, selectClientName)
//         );

//         await Promise.all(uploadTasks); // Wait for all uploads to complete
//         setUploadStatus('success'); // Set success status
//         resetUploader(); // Reset uploader after successful upload
//         getFilesFromStorage(selectClientName); // Refresh file list
//         initAutomation(scheduledFaxes);
//         updateDocStatus(
//           hpList.find((hp) => hp.docFax === faxNumber)!.docFax.toString(),
//           'active'
//         );
//         tableData.find(
//           (row) => row.hp.docFax === faxNumber
//         )!.hp.isAutomationActive = 'active';
//       } catch (error) {
//         console.error('Error uploading files:', error);
//         setUploadStatus('error'); // Set error status
//       }
//     },
//     [
//       allFiles,
//       resetUploader,
//       getFilesFromStorage,
//       selectClientName,
//       initAutomation,
//       updateDocStatus,
//       hpList,
//       tableData,
//       uploadFileGroup,
//     ]
//   );

//   const handlCancleAutomation = useCallback(
//     async (faxNumber: number, client: string) => {
//       // get task related to the hp
//       const hpTasks = clientRelatedTasks?.filter(
//         (task) => task.taskTarget === faxNumber
//       );
//       const tasksId = hpTasks
//         ?.filter((task) => {
//           if (task.status === 'active') {
//             return task.name.split('/').pop();
//           }
//         })
//         .map((task) => task.name.split('/').pop());

//       try {
//         fetch(
//           'http://127.0.0.1:5001/transparent-rx/us-central1/faxSenderCancel',
//           {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ tasksId }),
//           }
//         );
//       } catch (error) {
//         console.error('Error scheduling batch tasks:', error);
//       }

//       updateDocStatus(
//         hpList.find((hp) => hp.docFax === faxNumber)!.docFax.toString(),
//         'stopped'
//       );

//       setHpList((prev) =>
//         prev.map((hp) => {
//           if (hp.docFax === faxNumber) {
//             hp.isAutomationActive = 'stopped';
//           }
//           return hp;
//         })
//       );
//       const loops = new LoopsClient(process.env.VITE_LOOPS_API!);

//       // remove client from loops
//       await loops.updateContact(`${client.split('(')[1].slice(0, -1)}`, {
//         mailingLists: JSON.stringify({
//           cm4u4x6qf007w0nkzae3bg92w: false,
//           cm4u4xh3e00b60nk00g3a88p6: false,
//           cm4u4zyb900ia0ml88ehj1hxr: false,
//           cm4u504it00ew0kjogt789h1u: false,
//         }),
//       });
//     },
//     [clientRelatedTasks, hpList, updateDocStatus]
//   );

interface FaxFileEntry {
  files: File[]; // or any type you expect for files
  docFax: number;
  docName: string;
}

interface AllFilesType {
  welcomeEmail: { files: File[] };
  firstFax: FaxFileEntry[];
  faxReminder: FaxFileEntry[];
  callManufactureFax: FaxFileEntry[];
}

function AutomationManager() {
  const [clientList, setClientList] = useState<string[]>([]);
  const [clientsData, loadingClientsData] = useCollection(
    collection(db, 'clients'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [selectedClient, setSelectedClient] = useState<DocumentData | null>(
    null
  );
  // const [uploadStatus, setUploadStatus] = useState<
  //   'success' | 'error' | 'loading' | null
  // >(null);
  const [automationReady, setAutomationReady] = useState(false);
  const [automationFiles, setAutomationFiles] = useState<{
    clientFiles: {
      file: StorageReference;
      emailName: string;
      docName: string;
    }[];
    allFiles: AllFilesType;
  } | null>(null);

  const handleClientChange = useCallback(
    (value: string) => {
      const clientDoc = clientsData!.docs.find((doc) => {
        const data = doc.data();
        return `${data.fname} ${data.lname} (${data.email})` === value;
      });
      if (clientDoc) {
        setSelectedClient(clientDoc.data());
      }
    },
    [clientsData]
  );

  const handleStartAutomation = useCallback(() => {
    if (!automationFiles) {
      console.error('No file data available for automation.');
      return;
    }
    // Here you can use the file data from automationFiles to initiate your automation.
    console.log('Starting automation with:', automationFiles);

    // For example, call your API or run your automation tasks here.
    // Example:
    // startAutomationFunction(automationFiles, selectedClient);
  }, [automationFiles]);

  useEffect(() => {
    if (!loadingClientsData && clientsData) {
      const orderedList = clientsData!.docs.map((doc) => {
        if (
          doc.data().insurance.company === 'medicare' ||
          doc.data().employed === 'No Income in Household'
        )
          return (
            doc.data().fname +
            ' ' +
            doc.data().lname +
            ' (' +
            doc.data().email +
            ')'
          );
      });

      orderedList.sort((a, b) => {
        if (a! > b!) return 1;
        if (a! < b!) return -1;
        return 0;
      });
      setClientList(
        orderedList.filter((client) => client !== undefined) as string[]
      );
    }
  }, [loadingClientsData, clientsData]);

  useEffect(() => {
    if (automationFiles) {
      const { allFiles } = automationFiles;
      const allLoaded =
        allFiles.welcomeEmail.files.length > 0 &&
        allFiles.firstFax.length > 0 &&
        allFiles.faxReminder.length > 0 &&
        allFiles.callManufactureFax.length > 0;

      if (allLoaded) {
        setAutomationReady(true);
      } else {
        setAutomationReady(false);
      }
    }
  }, [automationFiles]);

  // const uploadStatusMessage = () => {
  //   return (
  //     <Snackbar
  //       open={!!uploadStatus}
  //       autoHideDuration={3000}
  //       onClose={() => setUploadStatus(null)} // Reset status on close
  //       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
  //       <Alert
  //         onClose={() => setUploadStatus(null)}
  //         severity={
  //           uploadStatus === 'success'
  //             ? 'success'
  //             : uploadStatus === 'loading'
  //             ? 'info'
  //             : 'error'
  //         }
  //         sx={{ width: '100%' }}>
  //         {uploadStatus === 'success'
  //           ? 'All files uploaded successfully!'
  //           : uploadStatus === 'loading'
  //           ? 'Uploading files...'
  //           : 'Error uploading files. Please try again.'}
  //       </Alert>
  //     </Snackbar>
  //   );
  // };

  return (
    <Stack className="container" sx={{ mt: 8 }}>
      {!loadingClientsData && clientsData ? (
        <>
          <Stack justifyContent={'center'} alignItems={'center'} gap={4}>
            <Typography variant="h2">
              Clients Files Uploader (Medicare & No Income)
            </Typography>
            <Stack className="tprx-card" width={'100%'}>
              <Grid container spacing={2} sx={{ fontSize: '1.2rem' }}>
                <ClientSelector
                  clientList={clientList}
                  selectedClient={selectedClient}
                  onClientChange={handleClientChange}
                />
                <Grid item sm={4}>
                  <Stack
                    spacing={3}
                    direction="row"
                    justifyContent={'flex-end'}
                    alignItems={'center'}>
                    <Button
                      size="small"
                      className="cta-main secondary-btn"
                      onClick={() => {
                        // if (row.isAutomationActive === 'active') {
                        //   onCancelAutomation(row.docFax);
                        // } else {
                        //   onUploadAllFiles(row.docFax);
                        // }
                      }}
                      disabled={automationReady || !selectedClient}>
                      <span style={{ fontSize: '1.2rem' }}>
                        Stop Automation
                      </span>
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleStartAutomation}
                      disabled={!automationReady || !selectedClient}>
                      <span style={{ fontSize: '1.2rem' }}>
                        Start Automation
                      </span>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              <AutomationTable
                selectedClient={selectedClient}
                onFilesUpdate={setAutomationFiles}
              />
            </Stack>
          </Stack>
        </>
      ) : (
        <div className="loader-s">
          <CircularProgress />
        </div>
      )}
    </Stack>
  );
}
export default AutomationManager;
