import { FormControl, Autocomplete, TextField, Grid } from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import { useState, useEffect } from 'react';

interface ClientSelectorProps {
  clientList: string[];
  selectedClient: DocumentData | null;
  onClientChange: (value: string) => void;
}

function ClientSelector({
  clientList,
  selectedClient,
  onClientChange,
}: ClientSelectorProps) {
  const [selectedClientName, setSelectedClientName] = useState<string | null>(
    null
  );
  useEffect(() => {
    if (selectedClient) {
      const selectedClientName = `${selectedClient.fname} ${selectedClient.lname} (${selectedClient.email})`;
      setSelectedClientName(selectedClientName);
    }
  }, [selectedClient]);

  return (
    <Grid item sm={8}>
      <FormControl fullWidth>
        <Autocomplete
          options={clientList}
          value={selectedClientName || null}
          onChange={(_e, value) => onClientChange(value as string)}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              fullWidth
              name="client"
              label="Select Client"
              variant="outlined"
            />
          )}
        />
      </FormControl>
    </Grid>
  );
}

export default ClientSelector;
