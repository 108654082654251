/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { collection, DocumentData } from 'firebase/firestore';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';
import { fplCalculator } from '../../utils/utilFuntions';
import EligibilityCalculatorResults from './eligibilityCalculatorResults';
import '../../App.css';
import theme from '../../theme';

export interface eligibilityValues {
  income: string;
  householdSize: number;
  medicationName: string;
  company: string;
  product: string;
  plan: string;
}

// Utility function for debouncing
const useDebounce = (func: Function, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunc = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => func(...args), delay);
    },
    [func, delay]
  );

  return debouncedFunc;
};

function EligibilityCalculator() {
  const [values, setValues] = useState<eligibilityValues>({
    income: '',
    householdSize: 1,
    medicationName: '',
    company: '',
    product: '',
    plan: '',
  });
  const [incomeValue, setIncomeValue] = useState<string | null>(
    values.income.toString() || ''
  );

  const [medications, loadingMedications] = useCollection(
    collection(db, 'medicationsBackup'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );
  const [medicationsList, setMedicationsList] = useState<DocumentData[] | null>(
    null
  );
  const [insuranceData, loadingInsuranceData] = useCollection(
    collection(db, 'insuranceCompanies'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [insuranceDataList, setInsuranceDataList] = useState<
    DocumentData[] | null
  >(null);
  const [insuranceProductList, setInsuranceProductList] = useState<
    DocumentData[] | null
  >(null);
  const [insurancePlanList, setInsurancePlanList] = useState<
    DocumentData[] | null
  >(null);

  const [clientFPL, setClientFPL] = useState<number>(0);
  const [scenario, setScenario] = useState('none');
  const [selectedMed, setSelectedMed] = useState<DocumentData | null>(null);
  const [source, setSource] = useState<string>('');
  const [isGeneric, setIsGeneric] = useState<boolean>(false);

  const checkProductsEligibilty = useCallback(() => {
    if (!selectedMed) return;
    setScenario('none');
    const isntEligible = clientFPL > selectedMed.fpl;

    if (selectedMed.brand) {
      isntEligible ? setScenario('papIncomeHigh') : setScenario('papEligible');
    } else {
      if (values.company === 'medicare') {
        setScenario('medicareGeneric');
      } else {
        setScenario('generic');
      }
      return;
    }

    if (selectedMed.name === 'Xolair') {
      Number(values.income) < 150000
        ? setScenario('papEligible')
        : setScenario('papIncomeHigh');
      return;
    }
  }, [clientFPL, selectedMed, values.company, values.income]);

  useEffect(() => {
    if (loadingMedications) return;
    let orderdList = medications?.docs.map((doc) => doc.data());
    orderdList = orderdList?.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    setMedicationsList(orderdList!);
  }, [loadingMedications, medications]);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    if (value === '' || value === undefined) return;

    setValues({ ...values, [name]: value });
  };
  function handleIncomeChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = e.target.value.replace(/\D/g, '');
    handleChange(e);
    if (value === '') {
      setValues({ ...values, income: '' });
      return;
    }
    value !== ''
      ? (value = '$' + parseInt(value).toLocaleString().replace(/\./g, ','))
      : (value = '');
    setIncomeValue(value);
  }

  const selectMedicationList = useMemo(() => {
    if (!medicationsList) return [];
    return medicationsList.filter((med) => {
      if (isGeneric) {
        return !med.brand;
      } else return true;
    });
  }, [medicationsList, isGeneric]);

  useEffect(() => {
    if (loadingInsuranceData) return;
    let orderdList = insuranceData?.docs.map((doc) => doc.data());
    orderdList = orderdList?.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    orderdList?.unshift({
      name: `I don't have insurance coverage at this time`,
    });
    if (source === 'pinnacle') {
      orderdList = orderdList?.filter(
        (item) => item.name === "America's Choice" || item.name === 'All State'
      );
      orderdList?.push({ name: "I can't find my insurance company" });
      orderdList?.push({
        name: "I don't have insurance coverage at this time",
      });
    }
    setInsuranceDataList(orderdList!);
  }, [loadingInsuranceData, insuranceData, source]);

  useEffect(() => {
    const insuranceProducts = insuranceDataList?.find(
      (item) => item.name === values.company
    );
    if (
      !insuranceProducts ||
      values.company === "I don't have insurance coverage at this time" ||
      values.company === "I can't find my insurance company"
    )
      return;
    insuranceProducts.products = insuranceProducts.products.sort(
      (a: { name: string }, b: { name: string }) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    );
    setInsuranceProductList(insuranceProducts?.products);
  }, [insuranceDataList, values]);

  useEffect(() => {
    const insurancePlans = insuranceProductList?.find(
      (item) => item.name === values.product
    );
    if (!insurancePlans) {
      setInsurancePlanList(null);
      return;
    }

    insurancePlans.plans = insurancePlans.plans.sort(
      (a: { name: string }, b: { name: string }) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    );
    setInsurancePlanList(insurancePlans?.plans);
  }, [insuranceProductList, values]);

  useEffect(() => {
    if (values.income === '' && values.company !== 'medicare') return;
    setClientFPL(
      fplCalculator(
        values.company !== 'medicare' ? values.householdSize : 1,
        values.company !== 'medicare'
          ? parseInt(values.income.slice(1).replace(/,/g, ''))
          : 0
      )
    );
    if (values.medicationName) {
      setSelectedMed(
        medicationsList!.find(
          (item) =>
            item.name ===
            values.medicationName.slice(0, values.medicationName.indexOf(' ('))
        ) || null
      );
    }
  }, [medicationsList, values]);

  const addValuestoParams = useCallback((values: eligibilityValues) => {
    const params = new URLSearchParams(window.location.search);
    Object.entries(values).forEach(([key, value]) => {
      if (value === '' || value === null) {
        params.delete(key);
      } else {
        params.set(key, value.toString());
      }
    });
    const newUrl = `${params.toString()}`;
    const urlForHistory = `${window.location.pathname}?${newUrl}`;
    window.history.pushState({ path: newUrl }, '', urlForHistory);
    window.parent.postMessage({ path: newUrl }, '*');
    return params;
  }, []);

  const debouncedAddValuestoParams = useDebounce(addValuestoParams, 300);

  useEffect(() => {
    debouncedAddValuestoParams(values);
  }, [debouncedAddValuestoParams, values]);
  useEffect(() => {
    if (!medicationsList) return;
    if (values.company === "I can't find my insurance company") {
      setIsGeneric(true);
    } else {
      setIsGeneric(false);
    }
    setMedicationsList(medicationsList);
  }, [values.company, medicationsList]);

  useEffect(() => {
    values.medicationName && checkProductsEligibilty();
  }, [checkProductsEligibilty, medicationsList, values.medicationName]);

  useEffect(() => {
    if (values.company) {
      setValues((prevValues) => ({
        ...prevValues,
        product: '',
        plan: '',
      }));
    }
  }, [values.company]);
  useEffect(() => {
    if (values.product) {
      setValues((prevValues) => ({
        ...prevValues,
        plan: '',
      }));
    }
  }, [values.product]);

  const filterMedicationsOptions = (options: any[], state: any) => {
    const inputValue = state.inputValue;
    return options.filter((option) => {
      if (
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.genericName.toLowerCase().includes(inputValue.toLowerCase())
      ) {
        return option;
      }
      return false;
    });
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
  });

  const handleMessage = (event: MessageEvent) => {
    const { data } = event;
    if (data && data.source) {
      setSource(data.source);
    }
  };

  window.addEventListener('message', handleMessage);
  const [takeMeds, setTakeMeds] = useState(false);

  return (
    <>
      {!takeMeds && (
        <Stack
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          sx={{
            paddingTop: {
              sm: 2,
              md: 8,
            },
          }}>
          <Stack spacing={8} className="container">
            <Stack
              p={2}
              sx={{
                background: theme.palette.primary.main,
                alignSelf: 'center',
                borderRadius: 8,
              }}>
              <Typography variant="h2" textAlign="center" color={'#fff'}>
                Step 1
              </Typography>
            </Stack>
            <Typography
              variant="h1"
              textAlign={'center'}
              fontSize={{
                sm: '3rem',
                md: '4.8rem',
              }}
              color={'#153b61'}>
              Does your client require to order medications?
            </Typography>
            <Stack
              justifyContent={'center'}
              alignItems={'stretch'}
              spacing={2}
              direction={{ sm: 'column', md: 'row' }}>
              <Stack className="tprx-card">
                <Typography variant="h6" textAlign={'center'}>
                  If they currently require no medications, the monthly
                  enrollment fee is just $48.
                </Typography>
                <Typography variant="body1" textAlign={'center'}>
                  Medications can be added at any time through the client
                  portal. Detailed instructions on how to proceed will be sent
                  to your client via email and SMS.
                </Typography>
                <Button
                  onClick={() =>
                    window.open(
                      `https://app.transparentpricerx.com/enroll?utm_source=brokerportal&utm_id=TPRX&utm_campaign=${source}`,
                      '_blank'
                    )
                  }
                  variant="contained"
                  sx={{
                    background: theme.palette.primary.dark,
                  }}>
                  Enroll Client Now
                </Button>
              </Stack>
              <Stack className="tprx-card">
                <Typography variant="h6" textAlign={'center'}>
                  If they require medications, there is a one-time payment of
                  $139, and a monthly fee of $48.
                </Typography>
                <Typography variant="body1" textAlign={'center'}>
                  Their first order of medication will start its process by the
                  end of the enrollment. Detailed instructions on how to proceed
                  will be sent to your client via email and SMS.
                </Typography>
                <Button onClick={() => setTakeMeds(true)} variant="contained">
                  Eligibility Calculator
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
      {takeMeds && (
        <>
          {medicationsList &&
          medicationsList.length > 0 &&
          insuranceDataList &&
          insuranceDataList.length > 0 ? (
            <Stack
              spacing={2}
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
              sx={{
                paddingTop: {
                  sm: 2,
                  md: 8,
                },
              }}>
              <Stack spacing={8} className="container">
                <Typography
                  variant="h1"
                  textAlign={'center'}
                  fontSize={{
                    sm: '3rem',
                    md: '4.8rem',
                  }}
                  color={'#153b61'}>
                  Check your client's eligibility
                </Typography>
                <Stack spacing={4} alignItems={'center'}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        id="income"
                        name="income"
                        label="Yearly Income"
                        onChange={handleIncomeChange}
                        value={
                          values.income.toString() !== '' ? incomeValue : ''
                        }
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        id="householdSize"
                        name="householdSize"
                        label="Household Number"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={values.householdSize || 1}
                        margin="normal"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Stack sx={{ marginTop: 2 }}>
                        <Autocomplete
                          filterOptions={filterMedicationsOptions}
                          disablePortal
                          options={selectMedicationList}
                          value={
                            selectMedicationList.find(
                              (option) =>
                                option.name ===
                                values.medicationName.split(' (')[0]
                            ) || null
                          }
                          getOptionLabel={(option: any) =>
                            `${option.name} (${option.genericName})`
                          }
                          onChange={(_e, value: any) => {
                            handleChange({
                              target: {
                                name: 'medicationName',
                                value: !value
                                  ? ''
                                  : `${value.name} (${value.genericName})`,
                              },
                            });
                          }}
                          renderOption={(props, option: any) => {
                            const { key, ...optionProps } = props;
                            return (
                              <Stack
                                key={key + option.name}
                                component="li"
                                {...optionProps}
                                direction={'row'}>
                                <Stack
                                  direction={'column'}
                                  mr={'auto'}
                                  key={key}>
                                  <Typography variant="body1">
                                    {option.name}
                                  </Typography>
                                  <Typography variant="body2">
                                    ({option.genericName})
                                  </Typography>
                                </Stack>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: '1.1rem',
                                    fontWeight: 600,
                                    backgroundColor: option.brand
                                      ? theme.palette.primary.dark
                                      : theme.palette.primary.main,
                                    color: theme.palette.common.white,
                                    padding: '0.2rem 0.5rem',
                                    borderRadius: '0.5rem',
                                    marginLeft: '2rem',
                                  }}>
                                  {option.brand ? 'Brand' : 'Generic'}
                                </Typography>
                              </Stack>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              value={values.medicationName || null}
                              name={'medicationName'}
                              label={'Select Medication'}
                              variant="outlined"
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        filterOptions={filterOptions}
                        disablePortal
                        value={values.company || null}
                        options={insuranceDataList.map((option) => option.name)}
                        onChange={(_e, value) => {
                          values.product = '';
                          values.plan = '';
                          if (value === "I can't find my insurance company") {
                            setInsuranceProductList(null);
                            setInsurancePlanList(null);
                            setSelectedMed(null);
                            values.medicationName = '';
                          }
                          const event = {
                            target: { name: 'company', value: value as string },
                          };
                          handleChange(event);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            name={`company`}
                            value={values.company || ''}
                            label={'Insurance Company'}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} key={'insurance product grid'}>
                      <TextField
                        key={'insurance product'}
                        fullWidth
                        select
                        required={
                          values.company ===
                            `I don't have insurance coverage at this time` ||
                          values.company === "I can't find my insurance company"
                            ? false
                            : true
                        }
                        name={'product'}
                        value={values.product || ''}
                        label={'Insurance Product'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          values.plan = '';
                          setInsurancePlanList(null);
                        }}
                        variant="outlined">
                        {insuranceProductList ? (
                          insuranceProductList?.map((item) => (
                            <MenuItem key={item.name} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value={''}>No Products Available</MenuItem>
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4} key={'insurance plan grid'}>
                      <TextField
                        key={'insurance plan'}
                        fullWidth
                        select
                        required={
                          (insurancePlanList &&
                            insurancePlanList.length === 1) ||
                          values.company ===
                            `I don't have insurance coverage at this time` ||
                          values.company === "I can't find my insurance company"
                            ? false
                            : true
                        }
                        name={'plan'}
                        value={values['plan'] || ''}
                        label={'Insurance Plan'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                        }}
                        variant="outlined">
                        {insurancePlanList ? (
                          insurancePlanList?.map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name ? item.name : ''}>
                              {item.name ? item.name : 'All'}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value={''}>No Plans Available</MenuItem>
                        )}
                      </TextField>
                    </Grid>
                  </Grid>
                  {scenario !== 'none' && values.company !== '' && (
                    <EligibilityCalculatorResults
                      values={values}
                      scenario={scenario}
                      insuranceDataList={insuranceDataList}
                      selectedMed={selectedMed}
                      source={source}
                    />
                  )}
                  {values.company === "I can't find my insurance company" &&
                    values.medicationName === '' && (
                      <Typography variant="h6" textAlign={'center'}>
                        If you can't find your insurance company, you can only
                        order generic medications
                      </Typography>
                    )}
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <div className="loader-s">
              <div className="loader" />
            </div>
          )}
        </>
      )}
    </>
  );
}
export default EligibilityCalculator;
