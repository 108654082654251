import React, {
  useCallback,
  useEffect,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import FaxUploaderComponenet from './FaxUploaderComponenet';
import { DocumentData, collection, getDocs } from 'firebase/firestore';
import { ref, listAll, getMetadata, StorageReference } from 'firebase/storage';
import { db, storage } from '../../../firebase-setup/firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import WelcomeEmailUploader from './WelcomeEmailUploaderComponenet';

interface FaxFileEntry {
  files: File[]; // or any type you expect for files
  docFax: number;
  docName: string;
}

interface AllFilesType {
  welcomeEmail: { files: File[] };
  firstFax: FaxFileEntry[];
  faxReminder: FaxFileEntry[];
  callManufactureFax: FaxFileEntry[];
}

// Define a union type for the fax keys
interface FaxType {
  name: 'firstFax' | 'faxReminder' | 'callManufactureFax';
}

interface AutomationTableProps {
  selectedClient: DocumentData | null;
  onFilesUpdate?: (filesData: {
    clientFiles: {
      file: StorageReference;
      emailName: string;
      docName: string;
    }[];
    allFiles: AllFilesType;
  }) => void;
}

function AutomationTable({
  selectedClient,
  onFilesUpdate,
}: AutomationTableProps) {
  const [docList, setDocList] = useState<
    {
      docName: string;
      docFax: number;
      isAutomationActive: any;
      medications: string[];
    }[]
  >([]);
  const [clientFiles, setClientFiles] = useState<
    { file: StorageReference; emailName: string; docName: string }[]
  >([]);
  const [meds, loadingMeds] = useCollection(collection(db, 'medications'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [brandMeds, setBrandMeds] = useState<string[]>([]);
  const [ordersList, setOrdersList] = useState<
    { docName: string; medication: string }[]
  >([]);
  const [allFiles, setAllFiles] = useState<AllFilesType | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(false);
  const getClientOrderList = useCallback(async (ssn: string) => {
    const orderCollectionRef = collection(db, `clients/${ssn}/orders`);
    const orderSnapshot = await getDocs(orderCollectionRef);
    const orderList = orderSnapshot.docs.map((doc) => {
      return {
        docName: doc.data().doctorName,
        medication: doc.data().medicationName,
      };
    });
    setOrdersList(orderList);
  }, []);

  const clientId = useRef('');
  const clientFolder = useRef('');
  const faxTypes: FaxType[] = useMemo(
    () => [
      { name: 'firstFax' },
      { name: 'faxReminder' },
      { name: 'callManufactureFax' },
    ],
    []
  );

  const areAllFaxFilesUploadedForDoctor = useCallback(
    (docName: string, allFiles: AllFilesType) => {
      return faxTypes.every((faxType) => {
        // Retrieve the list of files for this fax type; default to an empty array if none
        const filesForType = allFiles[faxType.name] || [];
        console.log(filesForType);
        // Check that at least one file for this fax type belongs to the doctor
        return filesForType.some((fileObj) => fileObj.docName === docName);
      });
    },
    [faxTypes]
  );

  const handleFaxFilesAdded = useCallback(
    (
      hpName: string,
      hpFax: number,
      id: string, // match the expected type signature
      files: File[]
    ) => {
      // Cast id to the allowed union type
      const faxType = id as 'firstFax' | 'faxReminder' | 'callManufactureFax';
      setAllFiles((prev: AllFilesType | null) => {
        // Create a new updated copy of the state
        const updatedFiles: AllFilesType = {
          ...prev,
          welcomeEmail: prev?.welcomeEmail || { files: [] },
          firstFax: prev?.firstFax || [],
          faxReminder: prev?.faxReminder || [],
          callManufactureFax: prev?.callManufactureFax || [],
        };
        // Append a new fax entry for the specified fax type.
        updatedFiles[faxType] = [
          ...updatedFiles[faxType],
          { files, docFax: hpFax, docName: hpName },
        ];
        console.log(
          `Added ${files.length} files for ${hpName} to the ${faxType} list`
        );
        // Check if all required fax files for this doctor are present.
        const isComplete = areAllFaxFilesUploadedForDoctor(
          hpName,
          updatedFiles
        );
        if (isComplete) {
          console.log(`${hpName} has all required fax files.`);
        } else {
          console.log(`${hpName} is missing some fax files.`);
        }
        return updatedFiles;
      });
    },
    [areAllFaxFilesUploadedForDoctor]
  );

  const handleFaxFileRemove = useCallback(
    (
      faxType: 'firstFax' | 'faxReminder' | 'callManufactureFax',
      index: number
    ) => {
      setAllFiles((prev: AllFilesType | null) => {
        // Copy the existing array for the given fax type.
        if (!prev) return prev;
        const updatedArray = [...prev[faxType]];
        updatedArray.splice(index, 1);
        return {
          ...prev,
          [faxType]: updatedArray,
        };
      });
    },
    []
  );
  const handleEmailFilesAdded = useCallback((files: File[]) => {
    setAllFiles((prev: AllFilesType | null) => {
      if (!prev) return prev;
      const updatedFiles: AllFilesType = {
        ...prev,
        welcomeEmail: { files: files },
      };
      // Check if the welcome email files array has at least one file.
      if (updatedFiles.welcomeEmail.files.length > 0) {
        console.log('Welcome email is uploaded');
      } else {
        console.log('Welcome email is missing');
      }
      return updatedFiles;
    });
  }, []);

  const handleEmailFileRemove = useCallback((index: number) => {
    setAllFiles((prev: AllFilesType | null) => {
      if (!prev) return prev;
      const updatedEmailFiles = [...prev.welcomeEmail.files];
      updatedEmailFiles.splice(index, 1);
      return {
        ...prev,
        welcomeEmail: { files: updatedEmailFiles },
      };
    });
  }, []);

  const getFilesFromStorage = useCallback(async (clientFolder: string) => {
    try {
      const storageRef = ref(storage, `/clientFiles/${clientFolder}`);
      const files = await listAll(storageRef);
      const filesMetadata = files.items.map(async (fileRef) => {
        const metadata = await getMetadata(fileRef);
        return metadata.customMetadata;
      });
      const filesMetadataResolved = await Promise.all(filesMetadata);
      const clientFiles = files.items.map((fileRef, index) => {
        return {
          file: fileRef,
          emailName: filesMetadataResolved[index]!.emailName,
          docName: filesMetadataResolved[index]!.docName,
        };
      });
      setClientFiles(clientFiles);
    } catch (error) {
      console.error('Error fetching files from storage:', error);
    }
  }, []);

  useEffect(() => {
    if (selectedClient) {
      setLoadingClient(true);
      const updateClientData = async () => {
        clientId.current = selectedClient.ssn;
        clientFolder.current = `${selectedClient.fname} ${selectedClient.lname} (${selectedClient.email})`;
        const orderCollectionRef = collection(
          db,
          `clients/${clientId.current}/orders`
        );
        const orderSnapshot = await getDocs(orderCollectionRef);
        const orderList = orderSnapshot.docs.map((doc) => {
          return {
            docName: doc.data().doctorName,
            medication: doc.data().medicationName,
          };
        });
        setOrdersList(orderList);

        const hpCollectionRef = collection(
          db,
          `clients/${clientId.current}/healthcare-providers`
        );
        const hpSnapshot = await getDocs(hpCollectionRef);
        const hpList = hpSnapshot.docs
          .map((doc) => {
            const data = doc.data();
            // Construct full name depending on whether mname exists and is non-empty
            const fullName =
              data.mname && data.mname.trim() !== ''
                ? `${data.fname} ${data.mname} ${data.lname}`
                : `${data.fname} ${data.lname}`;
            return {
              docName: fullName.trim(),
              docFax: Number(data.fax.replace(/\D/g, '')),
              isAutomationActive: data.automation,
              medications: [],
            };
          })
          .filter((hp) => hp.docName !== '');
        hpList.forEach((hp) => {
          const hpMeds = orderList
            .map((order) => {
              if (order.docName === hp.docName) return order.medication;
            })
            .filter((med): med is string => med !== undefined);
          if (hpMeds.length === 0) {
            hpList.splice(hpList.indexOf(hp), 1);
          }
        });
        setDocList(hpList);
        await getFilesFromStorage(clientFolder.current);
        setLoadingClient(false);
      };
      updateClientData();
    }
  }, [brandMeds, getClientOrderList, getFilesFromStorage, selectedClient]);

  useEffect(() => {
    docList.forEach((hp) => {
      const hpMeds = ordersList
        .map((order) => {
          if (order.docName === hp.docName) return order.medication;
        })
        .filter((med): med is string => med !== undefined);

      hp.medications = hpMeds;
      // const type = medicationType(hpMeds, brandMeds);
    });
  }, [brandMeds, docList, ordersList]);

  useEffect(() => {
    if (!loadingMeds && meds) {
      meds.docs.map((doc) => {
        doc.data().brand &&
          setBrandMeds((prev: any) => [...prev, doc.data().name]);
      });
    }
  }, [loadingMeds, meds]);

  useEffect(() => {
    if (onFilesUpdate && clientFiles && allFiles) {
      onFilesUpdate({ clientFiles, allFiles });
    }
  }, [clientFiles, allFiles, onFilesUpdate]);

  return (
    <>
      {loadingClient ? (
        <CircularProgress />
      ) : selectedClient && docList && docList.length > 0 ? (
        <>
          <TableContainer sx={{ display: 'flex' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="15%" sx={{ p: 2 }}>
                    <span style={{ fontSize: '1.3rem' }}>Doctor Name</span>
                  </TableCell>
                  <TableCell
                    align="center"
                    width="25%"
                    sx={{ p: 2, textAlign: 'left' }}>
                    <span style={{ fontSize: '1.3rem' }}>Medications</span>
                  </TableCell>
                  <TableCell align="center" width="20%" sx={{ p: 2 }}>
                    <span style={{ fontSize: '1.3rem' }}>First Fax</span>
                  </TableCell>
                  <TableCell align="center" width="20%" sx={{ p: 2 }}>
                    <span style={{ fontSize: '1.3rem' }}>Fax Reminder</span>
                  </TableCell>
                  <TableCell align="center" width="20%" sx={{ p: 2 }}>
                    <span style={{ fontSize: '1.3rem' }}>
                      Call Manufacture Fax
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docList.map((row, index) => (
                  <React.Fragment>
                    <TableRow
                      key={`${row.docName}-${index}`}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell
                        component="th"
                        scope="row"
                        width="15%"
                        sx={{ p: 2 }}>
                        <span style={{ fontSize: '1.2rem' }}>
                          {row.docName}
                        </span>
                      </TableCell>
                      <TableCell
                        align="center"
                        width="25%"
                        sx={{ p: 2, textAlign: 'left' }}>
                        {row.medications.map((med) => (
                          <Typography
                            variant="body2"
                            style={{ fontSize: '1.2rem' }}>
                            {med}
                          </Typography>
                        ))}
                      </TableCell>
                      {faxTypes.map((fax, faxIndex) => (
                        <TableCell
                          align="center"
                          width="15%"
                          sx={{ p: 2 }}
                          key={`${row.docName}-${fax.name}-${faxIndex}`}>
                          <FaxUploaderComponenet
                            clientFiles={clientFiles.filter(
                              (file) =>
                                file.emailName === fax.name &&
                                file.docName === row.docName
                            )}
                            docName={row.docName}
                            docFax={row.docFax}
                            uploaderId={fax.name}
                            onFilesAdded={handleFaxFilesAdded}
                            onFileRemove={(index: number) =>
                              handleFaxFileRemove(fax.name, index)
                            }
                            getFilesFromStorage={() =>
                              getFilesFromStorage(clientFolder.current)
                            }
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider
            sx={{
              width: '100%',
            }}
          />
          <Stack
            pl={2}
            spacing={6}
            direction="row"
            justifyContent={'flex-start'}
            alignItems={'center'}
            width={'100%'}>
            <Typography variant="body2" fontSize={'1.2rem'} flexShrink={0}>
              Upload Welcome Email Files
            </Typography>
            <WelcomeEmailUploader
              clientFiles={clientFiles.filter(
                (file) => file.emailName === 'welcomeEmail'
              )}
              onFilesAdded={handleEmailFilesAdded}
              onFileRemove={(index: number) => handleEmailFileRemove(index)}
              getFilesFromStorage={() =>
                getFilesFromStorage(clientFolder.current)
              }
            />
          </Stack>
        </>
      ) : (
        <Typography variant="body1">Choose a client </Typography>
      )}
    </>
  );
}

export default AutomationTable;

// const initFilesData = docList.reduce<
//   Record<
//     string,
//     {
//       welcomeEmail: boolean;
//       firstFax: boolean;
//       faxReminder: boolean;
//       manufacturerFax: boolean;
//     }
//   >
// >((acc, hp) => {
//   if (!docName) {
//     console.error('Invalid docName:', hp);
//     return acc; // Skip this iteration if docName is missing or invalid
//   }
//   acc[docName] = {
//     welcomeEmail:
//       clientFiles?.some(
//         (file) =>
//           file.docName === docName && file.emailName === 'welcomeEmail'
//       ) || false,
//     firstFax:
//       clientFiles?.some(
//         (file) => file.docName === docName && file.emailName === 'firstFax'
//       ) || false,
//     faxReminder:
//       clientFiles?.some(
//         (file) =>
//           file.docName === docName && file.emailName === 'faxReminder'
//       ) || false,
//     manufacturerFax:
//       clientFiles?.some(
//         (file) =>
//           file.docName === docName &&
//           file.emailName === 'callManufactureFax'
//       ) || false,
//   };
//   return acc;
// }, {});
// });
